@import url(https://fonts.googleapis.com/css?family=Rubik+Mono+One&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,500,600&display=swap&subset=latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #006d8f;
  border-radius: 4px;
}
body {
  background-image: url(/static/media/bg.47a1f05e.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: "Noto Sans", sans-serif;
}
button.btn.btn-primary {
  background: #12263a;
  border: 1px solid #12263a;
}
button.btn.btn-warning {
  background: #c5d8d1;
  border: 1px solid #c5d8d1;
}
button:hover {
  opacity: 0.8;
  transition: all 0.5s ease;
}
.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.flex-item-1 {
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
}
.flex-item-2 {
  flex: 0 0 auto;
}

.puzzle-block {
  position: relative;
  padding: 10px 15px;
}
.puzzle-block span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  font-family: "Rubik Mono One", sans-serif;
  z-index: 100;
  font-size: 1.5rem;
  pointer-events: none;
  text-shadow: 3px 2px #000;
}
.puzzle-block .img-holder {
  background-color: #000;
  box-shadow: 2px 1px 2px #000;
  border-radius: 10px;
}
.puzzle-block img {
  transition: all 0.4s ease;
  opacity: 0.5;
  border-radius: 10px;
}
.puzzle-block:hover img {
  -webkit-filter: grayscale(1) blur(1px);
          filter: grayscale(1) blur(1px);
}
.puzzle-block span b {
  font-size: 2rem;
  font-weight: 400;
}
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #f1f1f1;
  color: #666;
  top: 0px;
  height: 25px;
  width: 25px;
  border: 0;
  border-radius: 500%;
  cursor: pointer;
  margin-right: 0px;
  outline: none;
}
.form-radio:checked::before {
  position: absolute;
  left: 5px;
  top: -1px;
  content: "\2713";
  font-size: 20px;
  color: #fff;
}
.form-radio:hover {
  background-color: #eee;
}
.form-radio:checked {
  background-color: #ed254e;
}

.problem-results p {
  margin-bottom: 0;
  color: #414141;
}
.total-points {
  background: #12263a;
  text-align: center;
  padding: 20px;
  font-size: 1.1rem;
  color: #f2f2f2;
  margin: 4rem 0;
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 5px;
}
.total-points b {
  font-size: 1.4rem;
  padding-left: 10px;
  font-weight: 600;
}
.result-block {
  padding: 1rem;
  margin-bottom: 15px;
}
.user-block {
  background-color: #ed254e;
  padding: 2rem 1rem;
  margin-bottom: 15px;
  border-radius: 5px;
}
.user-block p {
  color: #f2f2f2;
  font-weight: 500;
}
.graph-title {
  float: left;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
.detail-block {
  font-size: 1rem;
  color: #414141;
  font-weight: 600;
  letter-spacing: 0.25px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2px;
  cursor: pointer;
}
.detail-name {
  width: 46%;
  font-size: 0.9rem;
  color: #12263a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 3px;
}
.detail-block:nth-child(even) .detail-name {
  background: #c5d8d1;
}
.detail-block:nth-child(odd) .detail-name {
  background: #e4ebe8;
}
.decimal {
  font-size: 0.8rem;
  background: #c5d8d1;
  width: 28px;
  text-align: center;
  height: 28px;
  border-radius: 50%;
  line-height: 27px;
  margin-top: 2px;
}
.detail-bar {
  width: 50%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-right: 2px;
}
.detail-bar:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0px;
  z-index: 1;
  background: #c7c7c7;
  margin-left: 0px;
  width: 1px;
  height: 100%;
}
.detail-bar .bar {
  background: #ed254e;
  float: right;
  height: 100%;
  position: relative;
}
span.zero-point {
  position: absolute;
  right: -12px;
  z-index: 1;
  font-size: 14px;
  top: 0px;
  color: #12263a;
}
span.one-point {
  position: absolute;
  left: -12px;
  z-index: 1;
  font-size: 14px;
  top: 0px;
  color: #12263a;
}
.details .detail-block:nth-child(2) .detail-bar::after {
  position: absolute;
  content: "";
  left: 50%;
  text-align: center;
  margin-left: -15px;
  top: -38px;
  background: url(/static/media/pointfive.350d6fdb.svg);
  height: 35px;
  width: 30px;
}
.details .detail-block:nth-child(9) .detail-bar::after {
  position: absolute;
  content: "";
  left: 50%;
  text-align: center;
  margin-left: -14px;
  bottom: -38px;
  background: url(/static/media/pointfive-r.96af7939.svg);
  background-repeat: no-repeat;
  height: 35px;
  width: 30px;
}
.details .detail-block:nth-child(even):hover {
  background: #c5d8d1;
}
.details .detail-block:nth-child(odd):hover {
  background: #e4ebe8;
}
/* Ruler */
.ruler {
  background: #ec254e;
  position: absolute;
  width: 100%;
  height: 1px;
  top: -2px;
}
.ruler span {
  background: #ec254e;
  position: absolute;
  top: -13px;
  width: 2px;
  height: 14px;
  z-index: 1;
  margin-right: -1px;
}
.ruler span i {
  position: absolute;
  top: -18px;
  width: 20px;
  font-size: 12px;
  text-align: center;
  left: -9px;
  font-style: normal;
  color: rgba(18, 38, 58, 0.5);
}
.ruler span:nth-child(1) {
  right: 1px;
}
.ruler span:nth-child(2) {
  right: 10%;
}
.ruler span:nth-child(3) {
  right: 20%;
}
.ruler span:nth-child(4) {
  right: 30%;
}
.ruler span:nth-child(5) {
  right: 40%;
}
.ruler span:nth-child(6) {
  right: 50%;
  background: transparent
}
.ruler span:nth-child(7) {
  right: 60%;
}
.ruler span:nth-child(8) {
  right: 70%;
}
.ruler span:nth-child(9) {
  right: 80%;
}
.ruler span:nth-child(10) {
  right: 90%;
}
.ruler span:nth-child(11) {
  right: 100%;
}
